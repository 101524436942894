<template lang="html">

  <section class="economic-information">
    <hero-global img="/img/hero/informacion-economica.svg" title="Económica" subtitle="Información Económica" />
    <section class="menu-anclas container">
      <router-link :class="[this.$route.hash == '#subvenciones-ayudas' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" to="#subvenciones-ayudas" @click="scrollSection('subvenciones-ayudas')">Subvenciones y Ayudas Económicas</router-link>
      <router-link to="#colaboraciones-economicas" @click="scrollSection('colaboraciones-economicas')" :class="[this.$route.hash == '#colaboraciones-economicas' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">Colaboraciones Económicas Privadas</router-link>
      <router-link to="#cuentas-presupuestos" @click="scrollSection('cuentas-presupuestos')" :class="[this.$route.hash == '#cuentas-presupuestos' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">Cuentas y Presupuestos Anuales</router-link>
    </section>
    <div class="container">
      <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive" v-model="menuAnclas" :activeMenuTop="menuTopAnclas">
        <option value="subvenciones-ayudas">
          Subvenciones y Ayudas Económicas
        </option>
        <option value="colaboraciones-economicas">
          Colaboraciones Económicas Privadas
        </option>
        <option value="cuentas-presupuestos">
          Cuentas y Presupuestos Anuales
        </option>
      
      </select>
    </div>
    <section id="subvenciones-ayudas" class="container">
      <section class="text-center position-relative ">
        <h2 class="title-grand title__double-center" data-text="Subvenciones y Ayudas Públicas">Subvenciones</h2>
        <div class="box-content container" v-if="Object.keys(store.subventions)">
          <ul class="menu-list container">
            <li v-for="(year, key) in Object.keys(store.subventions).reverse()" :key="key">
              <button :class="[yearSelected == year ? 'active-button' : '']" @click="yearSelected = year">Subvenciones {{year}}</button>
            </li>
          </ul>

          <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive submenu" v-model="menuDesplegable"  v-if="Object.keys(store.subventions)"> 
            <option v-for="(year, key) in Object.keys(store.subventions).reverse()" :key="key" :value="year">
          Subvenciones {{year}}
            </option>
          </select>
          <div class="content container">
            <div class="d-flex">
              <b class="title-decoration-bottom font-family-frank-ruhl text-left title-subv">Subvenciones {{yearSelected}}</b>
            </div>
            <table class="table-style" v-if="dataSubventions != 0 && dataSubventions">
              <tr class="tr-head">
                <td>Denominación subvención</td>
                <td>Objeto</td>
                <td>Cuantía económica</td>
                <td>Personas destinatarias</td>
                <td>Período de ejecución</td>
              </tr>

              <tr v-for="(i, key) in dataSubventions" :key="key">
                <td>
                  {{i.name}}
                </td>
                <td>{{i.object}}</td>
                <td>{{i.quantity}}</td>
                <td>{{i.recipients}}</td>
                <td>{{i.execute_period}}</td>
              </tr>

            </table>

            <div class="subvenciones-responsive" v-if="dataSubventions != 0 && dataSubventions">
              <ul v-for="(i, key) in dataSubventions" :key="key">
                <li><span>Denominación subvención: </span>{{i.name}}</li>
                <li><span>Objeto: </span>{{i.object}}</li>
                <li><span>Cuantía económica: </span>{{i.quantity}}</li>
                <li><span>Personas destinatarias: </span>{{i.recipients}}</li>
                <li><span>Período de ejecución: </span>{{i.execute_period}}</li>
                <hr>

              </ul>
            </div>

          </div>
        </div>
      </section>
    </section>
    <section id="colaboraciones-economicas" class="container text-center">
      <h2 class="title-grand title__double-center" data-text="Colaboraciones económicas privadas">Colaboraciones</h2>
      <p class="subtitle text-primary ">A continuación, se indican las entidades y empresas de carácter privado que apoyan con aportaciones económicas significativas las actividades sociales del CERMI:</p>
      <agile :options="myOptions" ref="carousel" class="logos-box container" v-if="partners && Object.values(partners).length != 0">
        <a v-for="(partner, key) in partners" :key="key" target="_blank" :href="partner.web" :title="'Logotipo de '+partner.name+'. Abre en ventana nueva'">
          <img :src="partner.logo.url" :alt="'Logotipo de '+partner.name+'. Abre en ventana nueva' " />
        </a>
      </agile>

    </section>
    <section id="cuentas-presupuestos" class="container">
      <div class="text-center">
        <h2 class="title-grand title__double-center" data-text="Cuentas y Presupuestos Anuales">Cuentas y Presupuestos</h2>
        <template v-for="(partner, key) in partners" :key="key"></template>
        <p class="subtitle">
          A través de este canal, haciendo efectivo el principio de transparencia, se informa de las Cuentas y Presupuestos Anuales del CERMI, proporcionado así y haciendo pública toda la documentación económica y financiera relevante de la Entidad.
        </p>
        <div class="cuentas-anuales container">
          <!-- <section class="section__title ">
                <p class="text-big">Cuentas anuales</p>
                <p class="text-small">Cuentas anuales del CERMI</p>
              </section> -->
          <p class="subtitle text-center color-primary text-normal">Las <strong>cuentas anuales del CERMI</strong> son auditadas por <strong>DELOITTE</strong>.</p>
          <p class="subtitle text-center color-primary text-normal">El CERMI está reconocido con los sellos</p>
          <div class="images-sellos">
            <img src="/img/sello-bequal.svg" alt="bequal">
            <img src="/img/sello-icong.svg" alt="icong">
            <img src="/img/sello-efr.svg" alt="efr">
          </div>
        </div>
      </div>
      <div :class="[
                showFeatureAll
                  ? 'boton-features-div'
                  : 'boton-features-div inactive'
              ]">
        <template v-if="showFeatureAll">
          <p class="title -intro features contraer">Contraer todo</p>
        </template>
        <template v-else>
          <p class="title -intro features contraer">Expandir todo</p>
        </template>
        <div class="boton-azul" @click="toggleFeatureAll">
          <div class="boton-circulo"></div>
        </div>
      </div>
      <ul class="list-item-dates">
        <template v-for="(year, key) in Object.values(arrayCompletYears)" :key="key">
          <li class="item-list" v-if="getDataBudget(year).items">
            <span class="date">{{year}}</span>
            <div class="content-date">
              <p class="title" @click="toggleFeatureItem(year)">Cuentas y Presupuestos Anuales {{year}}</p>
              <div v-if="getDataBudget(year).items" :class="[showFeatureAll ? 'feature list-download' : 'feature list-download inactive']" :data-year="year">
                <a v-for="(file, key) in getDataBudget(year).items" :key="key" :href="file.file.url" target="_blank" title="Descargar archivo" :download="file.name" :data-type="file.file.type">{{file.name}}</a>
              </div>
            </div>
          </li>
        </template>
        <div class="column-center">
          <button class="btn-white" v-if="showMore" @click="getMoreYear" title="Ver más" alt="Ver más"> Ver más</button>
        </div>
      </ul>
    </section>

    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
    <other-links-interest class="links-interest-box container" /> 
  </section>
</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { VueAgile } from "vue-agile";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'economic-information',
    props: [],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2,
      agile: VueAgile
    },
    setup() {
      const store = contentStore();
      store.loadSubventions()
      return {
        store
      }
    },
    mounted() {
      this.arrayCompletYears = this.arrayYears
      this.store.loadBudgets({ arrayYears: this.arrayYears, maxYear: this.maxYear })
      this.store.loadOrganizations({ catId: 9 })
      if (this.$route.hash) {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(element).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
      this.topMenuAnclas()
    },
    data() {
      return {
        menuAnclas: 'subvenciones-ayudas',
        menuDesplegable: new Date().getFullYear(),
        menuTopAnclas: false,
        mostrar: false,
        yearSelected: new Date().getFullYear(),
        maxYear: new Date().getFullYear(),
        arrayCompletYears: [],
        myOptions: {
          responsive: [{
            breakpoint: 900,
            settings: {
              navButtons: false,
              slidesToShow: 3,
              dots: true,
              centerMode: true,
              unagile: false,
            },
          }, {
            breakpoint: 800,
            settings: {
              navButtons: false,
              slidesToShow: 2,
              dots: true,
              unagile: false,
              autoplay: true,
            },
          },
          {
            breakpoint: 700,
            settings: {
              navButtons: false,
              slidesToShow: 2,
              dots: true,
              unagile: false,
              autoplay: true,
            },
          }, {
            breakpoint: 0,
            settings: {
              navButtons: false,
              slidesToShow: 1,
              dots: true,
              autoplay: true,
            },
          },],
        },
        showFeatureAll: true,
        showMore: true,
        minYear:2011
      }
    },
    methods: {
      toggleShowSubMenuResponsive: function (event) {
        if (this.mostrar == true) {
          this.mostrar = false;
        } else {
          this.mostrar = true;
        }

      },
      toggleFeatureAll: function () {
        this.showFeatureAll = !this.showFeatureAll
      },

      toggleFeatureItem: function (val) {
        if (val != null) {
          document.querySelector('.feature[data-year="' + val + '"]').classList.toggle("inactive")
        }
      },
      scrollSection: function (params) {
        if (params) {
          const offsetTop = document.getElementById(params).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      },
      topMenuAnclas: function () {
        window.onscroll = () => {
          if (window.scrollY > 0) {
            this.menuTopAnclas = true;
          } else {
            this.menuTopAnclas = false
          }
        }
      },
      range: function range(start, stop = undefined, step = 1) {
        const startArray = stop === undefined ? 0 : start;
        const stopArray = stop === undefined ? start : stop;
        return Array.from({ length: (stopArray - startArray) / step + 1 }, (_, i) => startArray + (i * step)).reverse();
      },
      getMoreYear: function name(maxyear) {
        this.maxYear = this.maxYear - 5
        this.arrayCompletYears = this.arrayCompletYears.concat(this.arrayYears)
        if (this.maxYear <= 2009) {
          this.showMore = false
        }
        this.store.loadBudgets({ arrayYears: this.arrayYears, maxYear: this.maxYear })
      },
      getDataBudget: function (year) {
        if (this.store.budgetsAnnual[year] && this.store.budgetsAnnual[year].items.length > 0) {
          return this.store.budgetsAnnual[year]
        }
        return "Lo sentimos no hay resultados"

      }
      //showMore ->  store.loadSubventions({ maxYear = null }) // pedimos las 10 anteriores (decada)
    },
    computed: {
      arrayYears() {
        return this.range(this.minYear, this.maxYear)
      },
      // minYear() {
      //   if (this.minYear <= 2011) {
      //     return 2011
      //   } else {
      //     return this.maxYear - 4

      //   }
      // },
      dataSubventions() {
        if (this.store.subventions[this.yearSelected]) {
          return this.store.subventions[this.yearSelected].items
        }
        return null
      },
      partners() {
        if (this.store.organizations[9]) {
          return this.store.organizations[9].items
        }
        return null
      },
    },
    watch: {
      "$route"() {
        if (this.$route.hash) {
          this.menuAnclas = this.$route.hash.replace('#', '')
          let element = this.$route.hash.replace('#', '')
          const offsetTop = document.getElementById(element).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      },
      "menuAnclas"() {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(this.menuAnclas).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      },
      "menuDesplegable"() {
        this.yearSelected = this.menuDesplegable
      }

    }

  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .economic-information {

    #colaboraciones-economicas .subtitle,
    #cuentas-presupuestos .subtitle {
      max-width: 68%;
    }

    .logos-box {
      max-width: 950px;
      padding: 30px 0;

      a {
        margin: 0;
        display: inline-block;
        text-align: center;
        padding: 0 20px;
        width: 100%;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center center;
        }
      }
    }


  }
</style>